import { defineStore } from 'pinia';

export interface SettingsState {
  themeSettings: any;
  redmonkeySettings: any;
  [key: string]: any;
}

export const useSettingsStore = defineStore('settings', {
  state: (): SettingsState => ({
    themeSettings: null,
    redmonkeySettings: null
  })
});
